import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/login'),
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: () => import('@/views/reset_password'),
    },
    {
      path: '/r/:token',
      name: 'password_change',
      component: () => import('./views/change_password'),
    },
  ],
})

export default router
